<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Home" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                  :class="{'is-invalid': isTabInvalid(title)}"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Section title"
                  v-model="sectionTitle"
                  :error="!!errors.section_title"
                  :error-messages="errors.section_title"
                  @input="delete errors.section_title"
                />
                <va-select
                  label="Section theme"
                  v-model="sectionTheme"
                  :options="sectionThemes"
                  :error="!!errors.section_theme"
                  @input="delete errors.section_theme"
                  no-clear
                />
                <va-input
                  label="Label *"
                  v-model="label"
                  :error="!!errors.label"
                  :error-messages="errors.label"
                  @input="delete errors.label"
                />
                <va-input
                  label="Second"
                  v-model="second"
                  :error="!!errors.second_label"
                  :error-messages="errors.second_label"
                  @input="delete errors.second_label"
                />
                <va-input
                  type="textarea"
                  label="Description *"
                  v-model="description"
                  :error="!!errors.description"
                  :error-messages="errors.description"
                  @input="delete errors.description"
                />
                <va-button
                  @click="submit()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <div class="row">
                  <div class="flex xs6">
                    <va-select
                      v-model="selectedBlock"
                      no-clear
                      :options="blocks">
                    </va-select>
                  </div>
                  <div class="flex xs6">
                    <va-button style="margin-bottom: 10px;" small @click="addInput">Додати</va-button>
                  </div>
                </div>
                <draggable v-model="content" group="people" handle=".handle">
                  <div
                    class="mb-4"
                    v-for="(contentItem, index) in content"
                    :key="index"
                  >
                    <va-card stripe="info" :title="contentItem.type">
                      <div class="text-right">
                        <i class="fa fa-align-justify handle"></i>
                      </div>
                      <div v-if="contentItem.type === 'two-text'">
                        <div class="row">
                          <div class="flex xs2">
                            <va-toggle small label="Is anchor" v-model="contentItem.is_anchor"></va-toggle>
                          </div>
                          <div class="flex xs4">
                            <va-input
                              label="Anchor title"
                              v-model="contentItem.anchor_title"
                              @input="delete errors[`TwoTextBlock[${index}][anchor_title]`]"
                              :error="!!errors[`TwoTextBlock[${index}][anchor_title]`]"
                              :error-messages="errors[`TwoTextBlock[${index}][anchor_title]`]"
                            />
                          </div>
                        </div>
                        <va-input
                          label="Title"
                          v-model="contentItem.title"
                          @input="delete errors[`TwoTextBlock[${index}][title]`]"
                          :error="!!errors[`TwoTextBlock[${index}][title]`]"
                          :error-messages="errors[`TwoTextBlock[${index}][title]`]"
                        />
                        <va-input
                          type="textarea"
                          label="Text"
                          v-model="contentItem.text"
                          @input="delete errors[`TwoTextBlock[${index}][text]`]"
                          :error="!!errors[`TwoTextBlock[${index}][text]`]"
                          :error-messages="errors[`TwoTextBlock[${index}][text]`]"
                        />
                        <va-select label="text align" :options="positioning" v-model="contentItem.image_align" no-clear></va-select>
                        <va-card class="mb-3" title="Image">
                          <a v-if="contentItem.imageExisting.src" style="display: block;" target="_blank" :href="adminUrl + contentItem.imageExisting.src"><img style="max-width: 400px;" :src="adminUrl + contentItem.imageExisting.src"></a>
                          <span>Завантажити нове зображення</span>
                          <va-file-upload
                            type="gallery"
                            dropzone
                            v-model="contentItem.imageNew"
                            style="margin-bottom: 25px;"
                          />
                          <va-button @click="uploadImage(`[${index}]image`, 'builder_two_text_image', `TwoTextBlock[${index}][image]`, contentItem.imageNew[0], 'common\\models\\builder\\TwoTextBlock', contentItem.id, contentItem.target_sign)">Завантажити</va-button>
                          <div v-if="errors[`TwoTextBlock[${index}][image]`]" style="color: red;">{{ errors[`TwoTextBlock[${index}][image]`][0] }}</div>
                        </va-card>
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="contentItem.type === 'principles-block'">
                        <div class="row">
                          <div class="flex xs2">
                            <va-toggle small label="Is anchor" v-model="contentItem.is_anchor"></va-toggle>
                          </div>
                          <div class="flex xs4">
                            <va-input
                              label="Anchor title"
                              v-model="contentItem.anchor_title"
                              @input="delete errors[`PrinciplesBlock[${index}][anchor_title]`]"
                              :error="!!errors[`PrinciplesBlock[${index}][anchor_title]`]"
                              :error-messages="errors[`PrinciplesBlock[${index}][anchor_title]`]"
                            />
                          </div>
                        </div>
                        <va-input
                          label="Title"
                          v-model="contentItem.title"
                          @input="delete errors[`PrinciplesBlock[${index}][title]`]"
                          :error="!!errors[`PrinciplesBlock[${index}][title]`]"
                          :error-messages="errors[`PrinciplesBlock[${index}][title]`]"
                        />
                        <va-input
                          label="Left Title *"
                          v-model="contentItem.left_title"
                          @input="delete errors[`PrinciplesBlock[${index}][left_title]`]"
                          :error="!!errors[`PrinciplesBlock[${index}][left_title]`]"
                          :error-messages="errors[`PrinciplesBlock[${index}][left_title]`]"
                        />
                        <va-input
                          label="Left Text *"
                          v-model="contentItem.left_text"
                          @input="delete errors[`PrinciplesBlock[${index}][left_text]`]"
                          :error="!!errors[`PrinciplesBlock[${index}][left_text]`]"
                          :error-messages="errors[`PrinciplesBlock[${index}][left_text]`]"
                        />
                        <va-input
                          label="Center Title *"
                          v-model="contentItem.center_title"
                          @input="delete errors[`PrinciplesBlock[${index}][center_title]`]"
                          :error="!!errors[`PrinciplesBlock[${index}][center_title]`]"
                          :error-messages="errors[`PrinciplesBlock[${index}][center_title]`]"
                        />
                        <va-input
                          label="Center Text *"
                          v-model="contentItem.center_text"
                          @input="delete errors[`PrinciplesBlock[${index}][center_text]`]"
                          :error="!!errors[`PrinciplesBlock[${index}][center_text]`]"
                          :error-messages="errors[`PrinciplesBlock[${index}][center_text]`]"
                        />
                        <va-input
                          label="Right Title *"
                          v-model="contentItem.right_title"
                          @input="delete errors[`PrinciplesBlock[${index}][right_title]`]"
                          :error="!!errors[`PrinciplesBlock[${index}][right_title]`]"
                          :error-messages="errors[`PrinciplesBlock[${index}][right_title]`]"
                        />
                        <va-input
                          label="Right Text *"
                          v-model="contentItem.right_text"
                          @input="delete errors[`PrinciplesBlock[${index}][right_text]`]"
                          :error="!!errors[`PrinciplesBlock[${index}][right_text]`]"
                          :error-messages="errors[`PrinciplesBlock[${index}][right_text]`]"
                        />
                        <va-card class="mb-3" title="Image">
                          <a v-if="contentItem.imageExisting.src" style="display: block;" target="_blank" :href="adminUrl + contentItem.imageExisting.src"><img style="max-width: 400px;" :src="adminUrl + contentItem.imageExisting.src"></a>
                          <span>Завантажити нове зображення</span>
                          <va-file-upload
                            type="gallery"
                            dropzone
                            @input="delete errors[`PrinciplesBlock[${index}][image]`]"
                            v-model="contentItem.imageNew"
                            style="margin-bottom: 25px;"
                          />
                          <va-button @click="uploadImage(`[${index}]image`, 'builder_interest_block_image', `PrinciplesBlock[${index}][image]`, contentItem.imageNew[0], 'common\\models\\builder\\PrinciplesBlock', contentItem.id, contentItem.target_sign)">Завантажити</va-button>
                          <div v-if="errors[`PrinciplesBlock[${index}][image]`]" style="color: red;">{{ errors[`PrinciplesBlock[${index}][image]`][0] }}</div>
                        </va-card>
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="contentItem.type === 'values-block'">
                        <div class="row">
                          <div class="flex xs2">
                            <va-toggle small label="Is anchor" v-model="contentItem.is_anchor"></va-toggle>
                          </div>
                          <div class="flex xs4">
                            <va-input
                              label="Anchor title"
                              v-model="contentItem.anchor_title"
                              @input="delete errors[`ValueBlock[${index}][anchor_title]`]"
                              :error="!!errors[`ValueBlock[${index}][anchor_title]`]"
                              :error-messages="errors[`ValueBlock[${index}][anchor_title]`]"
                            />
                          </div>
                        </div>
                        <va-input
                          label="Title"
                          v-model="contentItem.title"
                          @input="delete errors[`ValueBlock[${index}][title]`]"
                          :error="!!errors[`ValueBlock[${index}][title]`]"
                          :error-messages="errors[`ValueBlock[${index}][title]`]"
                        />
                        <va-card class="mb-3" title="Left Image">
                          <a v-if="contentItem.left_image_existing.src" style="display: block;" target="_blank" :href="adminUrl + contentItem.left_image_existing.src"><img style="max-width: 400px;" :src="adminUrl + contentItem.left_image_existing.src"></a>
                          <span>Завантажити нове зображення</span>
                          <va-file-upload
                            type="gallery"
                            dropzone
                            @input="delete errors[`ValueBlock[${index}][left_image]`]"
                            v-model="contentItem.left_image_new"
                            style="margin-bottom: 25px;"
                          />
                          <va-button @click="uploadImage(`[${index}]left_image`, 'builder_value_block_left_image', `ValueBlock[${index}][left_image]`, contentItem.left_image_new[0], 'common\\models\\builder\\ValueBlock', contentItem.id, contentItem.target_sign)">Завантажити</va-button>
                          <div v-if="errors[`ValueBlock[${index}][left_image]`]" style="color: red;">{{ errors[`ValueBlock[${index}][left_image]`][0] }}</div>
                        </va-card>
                        <va-input
                          label="Left Title *"
                          v-model="contentItem.left_title"
                          @input="delete errors[`ValueBlock[${index}][left_title]`]"
                          :error="!!errors[`ValueBlock[${index}][left_title]`]"
                          :error-messages="errors[`ValueBlock[${index}][left_title]`]"
                        />
                        <va-input
                          label="Left Text *"
                          v-model="contentItem.left_text"
                          @input="delete errors[`ValueBlock[${index}][left_text]`]"
                          :error="!!errors[`ValueBlock[${index}][left_text]`]"
                          :error-messages="errors[`ValueBlock[${index}][left_text]`]"
                        />
                        <va-card class="mb-3" title="Center Image">
                          <a v-if="contentItem.center_image_existing.src" style="display: block;" target="_blank" :href="adminUrl + contentItem.center_image_existing.src"><img style="max-width: 400px;" :src="adminUrl + contentItem.center_image_existing.src"></a>
                          <span>Завантажити нове зображення</span>
                          <va-file-upload
                            type="gallery"
                            dropzone
                            @input="delete errors[`ValueBlock[${index}][center_image]`]"
                            v-model="contentItem.center_image_new"
                            style="margin-bottom: 25px;"
                          />
                          <va-button @click="uploadImage(`[${index}]center_image`, 'builder_value_block_center_image', `ValueBlock[${index}][center_image]`, contentItem.center_image_new[0], 'common\\models\\builder\\ValueBlock', contentItem.id, contentItem.target_sign)">Завантажити</va-button>
                          <div v-if="errors[`ValueBlock[${index}][center_image]`]" style="color: red;">{{ errors[`ValueBlock[${index}][center_image]`][0] }}</div>
                        </va-card>
                        <va-input
                          label="Center Title *"
                          v-model="contentItem.center_title"
                          @input="delete errors[`ValueBlock[${index}][center_title]`]"
                          :error="!!errors[`ValueBlock[${index}][center_title]`]"
                          :error-messages="errors[`ValueBlock[${index}][center_title]`]"
                        />
                        <va-input
                          label="Center Text *"
                          v-model="contentItem.center_text"
                          @input="delete errors[`ValueBlock[${index}][center_text]`]"
                          :error="!!errors[`ValueBlock[${index}][center_text]`]"
                          :error-messages="errors[`ValueBlock[${index}][center_text]`]"
                        />
                        <va-card class="mb-3" title="Right Image">
                          <a v-if="contentItem.right_image_existing.src" style="display: block;" target="_blank" :href="adminUrl + contentItem.right_image_existing.src"><img style="max-width: 400px;" :src="adminUrl + contentItem.right_image_existing.src"></a>
                          <span>Завантажити нове зображення</span>
                          <va-file-upload
                            type="gallery"
                            dropzone
                            @input="delete errors[`ValueBlock[${index}][right_image]`]"
                            v-model="contentItem.right_image_new"
                            style="margin-bottom: 25px;"
                          />
                          <va-button @click="uploadImage(`[${index}]right_image`, 'builder_value_block_right_image', `ValueBlock[${index}][right_image]`, contentItem.right_image_new[0], 'common\\models\\builder\\ValueBlock', contentItem.id, contentItem.target_sign)">Завантажити</va-button>
                          <div v-if="errors[`ValueBlock[${index}][right_image]`]" style="color: red;">{{ errors[`ValueBlock[${index}][right_image]`][0] }}</div>
                        </va-card>
                        <va-input
                          label="Right Title *"
                          v-model="contentItem.right_title"
                          @input="delete errors[`ValueBlock[${index}][right_title]`]"
                          :error="!!errors[`ValueBlock[${index}][right_title]`]"
                          :error-messages="errors[`ValueBlock[${index}][right_title]`]"
                        />
                        <va-input
                          label="Right Text *"
                          v-model="contentItem.right_text"
                          @input="delete errors[`ValueBlock[${index}][right_text]`]"
                          :error="!!errors[`ValueBlock[${index}][right_text]`]"
                          :error-messages="errors[`ValueBlock[${index}][right_text]`]"
                        />
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="contentItem.type === 'lviv-text'">
                        <div class="row">
                          <div class="flex xs2">
                            <va-toggle small label="Is anchor" v-model="contentItem.is_anchor"></va-toggle>
                          </div>
                          <div class="flex xs4">
                            <va-input
                              label="Anchor title"
                              v-model="contentItem.anchor_title"
                              @input="delete errors[`LvivTextBlock[${index}][anchor_title]`]"
                              :error="!!errors[`LvivTextBlock[${index}][anchor_title]`]"
                              :error-messages="errors[`LvivTextBlock[${index}][anchor_title]`]"
                            />
                          </div>
                        </div>
                        <va-input
                          label="Label"
                          v-model="contentItem.label"
                          @input="delete errors[`LvivTextBlock[${index}][label]`]"
                          :error="!!errors[`LvivTextBlock[${index}][label]`]"
                          :error-messages="errors[`LvivTextBlock[${index}][label]`]"
                        />
                        <va-input
                          label="Second Label"
                          v-model="contentItem.second_label"
                          @input="delete errors[`LvivTextBlock[${index}][second_label]`]"
                          :error="!!errors[`LvivTextBlock[${index}][second_label]`]"
                          :error-messages="errors[`LvivTextBlock[${index}][second_label]`]"
                        />
                        <va-input
                          label="Title"
                          v-model="contentItem.title"
                          @input="delete errors[`LvivTextBlock[${index}][title]`]"
                          :error="!!errors[`LvivTextBlock[${index}][title]`]"
                          :error-messages="errors[`LvivTextBlock[${index}][title]`]"
                        />
                        <div style="margin-bottom: 15px;">
                          <span>Description *</span>
                          <ckeditor
                            v-model="contentItem.description"
                            :editor="editor"
                            :config="editorConfig"
                            @input="delete errors[`LvivTextBlock[${index}][description]`]"
                          ></ckeditor>
                          <div v-if="errors[`LvivTextBlock[${index}][description]`]" style="color: red;">{{ errors[`LvivTextBlock[${index}][description]`][0] }}</div>
                        </div>
                        <va-select label="float *" :options="positioning" v-model="contentItem.float" no-clear></va-select>
                        <va-card class="mb-3" title="Image">
                          <a v-if="contentItem.imageExisting.src" style="display: block;" target="_blank" :href="adminUrl + contentItem.imageExisting.src"><img style="max-width: 400px;" :src="adminUrl + contentItem.imageExisting.src"></a>
                          <span>Завантажити нове зображення</span>
                          <va-file-upload
                            type="gallery"
                            dropzone
                            @input="delete errors[`LvivTextBlock[${index}][image]`]"
                            v-model="contentItem.imageNew"
                            style="margin-bottom: 25px;"
                          />
                          <va-button @click="uploadImage(`[${index}]image`, 'builder_lviv_text_block_image', `LvivTextBlock[${index}][image]`, contentItem.imageNew[0], 'common\\models\\builder\\LvivTextBlock', contentItem.id, contentItem.target_sign)">Завантажити</va-button>
                          <div v-if="errors[`LvivTextBlock[${index}][image]`]" style="color: red;">{{ errors[`LvivTextBlock[${index}][image]`][0] }}</div>
                        </va-card>
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="contentItem.type === 'about-activities'">
                        <div class="row">
                          <div class="flex xs2">
                            <va-toggle small label="Is anchor" v-model="contentItem.is_anchor"></va-toggle>
                          </div>
                          <div class="flex xs4">
                            <va-input
                              label="Anchor title"
                              v-model="contentItem.anchor_title"
                              @input="delete errors[`ActivitiesBlock[${index}][anchor_title]`]"
                              :error="!!errors[`ActivitiesBlock[${index}][anchor_title]`]"
                              :error-messages="errors[`ActivitiesBlock[${index}][anchor_title]`]"
                            />
                          </div>
                        </div>
                        <va-input
                          label="Title"
                          v-model="contentItem.title"
                          @input="delete errors[`ActivitiesBlock[${index}][title]`]"
                          :error="!!errors[`ActivitiesBlock[${index}][title]`]"
                          :error-messages="errors[`ActivitiesBlock[${index}][title]`]"
                        />
                        <va-input
                          label="Subtitle"
                          v-model="contentItem.subtitle"
                          @input="delete errors[`ActivitiesBlock[${index}][subtitle]`]"
                          :error="!!errors[`ActivitiesBlock[${index}][subtitle]`]"
                          :error-messages="errors[`ActivitiesBlock[${index}][subtitle]`]"
                        />
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="contentItem.type === 'news'">
                        <div class="row">
                          <div class="flex xs2">
                            <va-toggle small label="Is anchor" v-model="contentItem.is_anchor"></va-toggle>
                          </div>
                          <div class="flex xs4">
                            <va-input
                              label="Anchor title"
                              v-model="contentItem.anchor_title"
                              @input="delete errors[`ArticlesListBlock[${index}][anchor_title]`]"
                              :error="!!errors[`ArticlesListBlock[${index}][anchor_title]`]"
                              :error-messages="errors[`ArticlesListBlock[${index}][anchor_title]`]"
                            />
                          </div>
                        </div>
                        <va-input
                          label="Title *"
                          v-model="contentItem.title"
                          @input="delete errors[`ArticlesListBlock[${index}][title]`]"
                          :error="!!errors[`ArticlesListBlock[${index}][title]`]"
                          :error-messages="errors[`ArticlesListBlock[${index}][title]`]"
                        />
                        <va-input
                          label="Subtitle"
                          v-model="contentItem.subtitle"
                          @input="delete errors[`ArticlesListBlock[${index}][subtitle]`]"
                          :error="!!errors[`ArticlesListBlock[${index}][subtitle]`]"
                          :error-messages="errors[`ArticlesListBlock[${index}][subtitle]`]"
                        />
                        <va-input
                          label="Read more label *"
                          v-model="contentItem.read_more_label"
                          @input="delete errors[`ArticlesListBlock[${index}][read_more_label]`]"
                          :error="!!errors[`ArticlesListBlock[${index}][read_more_label]`]"
                          :error-messages="errors[`ArticlesListBlock[${index}][read_more_label]`]"
                        />
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                    </va-card>
                  </div>
                </draggable>
                <va-button
                  @click="submit()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 2">
                <va-input
                  label="Meta Title"
                  v-model="metaTitle"
                />
                <va-input
                  label="Meta Description"
                  type="textarea"
                  v-model="metaDescription"
                />
                <va-input
                  label="Seo Title"
                  v-model="seoTitle"
                />
                <div style="margin-bottom: 15px;">
                  <span>Seo Text</span>
                  <ckeditor v-model="seoText" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <va-input
                  label="SEO OpenGraph Title"
                  v-model="seoOpenGraphTitle"
                />
                <va-input
                  type="textarea"
                  label="SEO OpenGraph Description"
                  v-model="seoOpenGraphDescription"
                />
                <va-card class="mb-3" title="SEO OpenGraph Image">
                  <div v-if="seoOpenGraphImageExisting">
                    <a style="display: block;" target="_blank" :href="seoOpenGraphImageExisting"><img style="max-width: 400px;" :src="seoOpenGraphImageExisting"></a>
                    <div><va-button small color="danger" @click="seoOpenGraphImageExisting = deleteImage(seoOpenGraphImageId) ? '' : seoOpenGraphImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="seoOpenGraphImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage(`image`, null, `SeoEntity[image]`, seoOpenGraphImage[0], 'common\\models\\SeoEntity', seoId, generateSign(32))">Завантажити</va-button>
                </va-card>
                <va-button
                  @click="submit()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue'
import draggable from 'vuedraggable'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
    draggable: draggable,
  },
  data () {
    return {
      sectionTitle: '',
      sectionTheme: '',
      sectionThemes: [],
      label: '',
      second: '',
      description: '',

      content: [],
      blocks: [
        {
          id: 'two-text',
          text: 'Two Text',
        },
        {
          id: 'principles-block',
          text: 'Principles Block',
        },
        {
          id: 'values-block',
          text: 'Values Block',
        },
        {
          id: 'lviv-text',
          text: 'Chess Block',
        },
        {
          id: 'about-activities',
          text: 'Activities Block',
        },
        {
          id: 'news',
          text: 'Articles List',
        },
      ],
      selectedBlock: {
        id: 'two-text',
        text: 'Two Text',
      },
      positioning: ['left', 'right'],

      seoId: '',
      metaTitle: '',
      metaDescription: '',
      seoTitle: '',
      seoText: '',
      seoOpenGraphTitle: '',
      seoOpenGraphDescription: '',
      seoOpenGraphImage: [],
      seoOpenGraphImageExisting: '',
      seoOpenGraphImageId: '',

      errors: [],

      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_SHKIL_API_URL}/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      tabTitles: ['Main', 'Content', 'Seo'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        section_title: this.sectionTitle,
        section_theme: this.sectionTheme.id,
        label: this.label,
        second_label: this.second,
        description: this.description,

        TwoTextBlock: this.getBlocks('two-text'),
        PrinciplesBlock: this.getBlocks('principles-block'),
        ValueBlock: this.getBlocks('values-block'),
        LvivTextBlock: this.getBlocks('lviv-text'),
        ActivitiesBlock: this.getBlocks('about-activities'),
        ArticlesListBlock: this.getBlocks('news'),

        SeoEntity: {
          title: this.metaTitle,
          description: this.metaDescription,
          seo_title: this.seoTitle,
          text: this.seoText,
          og_title: this.seoOpenGraphTitle,
          og_description: this.seoOpenGraphDescription,
        },
      }
    },
    adminUrl () {
      return process.env.VUE_APP_SHKIL_ADMIN
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    isTabInvalid (name) {
      const tab = this.tabTitles.findIndex(item => item === name)

      if (tab === 0) {
        return !!this.errors.section_title || !!this.errors.section_theme || !!this.errors.label || !!this.errors.second_label ||
          !!this.errors.description
      } else if (tab === 1) {
        const errors = Object.keys(this.errors)
        return errors.some(i => {
          return i.includes('TwoTextBlock') ||
            i.includes('PrinciplesBlock') ||
            i.includes('ValueBlock') ||
            i.includes('LvivTextBlock') ||
            i.includes('ActivitiesBlock') ||
            i.includes('ArticlesListBlock')
        })
      } else if (tab === 2) {
        return false
      }
      return false
    },
    generateSign (length) {
      return Math.random().toString(20).substr(2, length)
    },
    getBlocks (name) {
      const blocks = this.content.filter(i => i.type === name)
      if (blocks.length) {
        const newArray = {}
        blocks.forEach(i => {
          const index = this.content.findIndex(j => {
            return i.id ? i.id === j.id : i.target_sign === j.target_sign
          })
          const newObj = Object.assign({}, i)
          newObj.is_anchor = i.is_anchor ? 1 : 0
          newArray[index] = newObj
        })
        return newArray
      }
      return []
    },
    uploadImage (attr, entityAttribute, param, file, model = 'backend\\modules\\article\\models\\Article', id = this.$attrs.id, sign = this.sign) {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('model_name', model)
      formData.append('attribute', attr)
      formData.append(param, file)
      formData.append('file_id', '0')
      formData.append('multiple', '0')
      if (entityAttribute !== null) {
        formData.append('entity_attribute', entityAttribute)
      }

      if (id) {
        formData.append('id', id)
      } else {
        formData.append('sign', sign)
      }

      axios.post(`${process.env.VUE_APP_SHKIL_API_URL}/admin/upload?lang=${this.locale}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_SHKIL_API_URL}/admin/upload/${id}?lang=${this.locale}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    addInput () {
      switch (this.selectedBlock.id) {
        case 'two-text':
          this.content.push({
            type: 'two-text',
            is_anchor: false,
            anchor_title: '',
            target_sign: this.generateSign(32),
            title: '',
            text: '',
            image_align: 'left',
            imageExisting: {
              src: null,
            },
            newImage: [],
          })
          break
        case 'principles-block':
          this.content.push({
            type: 'principles-block',
            is_anchor: false,
            anchor_title: '',
            target_sign: this.generateSign(32),
            title: '',
            left_title: '',
            left_text: '',
            center_title: '',
            center_text: '',
            right_title: '',
            right_text: '',
            imageExisting: {
              src: null,
            },
            newImage: [],
          })
          break
        case 'values-block':
          this.content.push({
            type: 'values-block',
            is_anchor: false,
            anchor_title: '',
            target_sign: this.generateSign(32),
            title: '',
            left_title: '',
            left_text: '',
            left_image_existing: '',
            left_image_new: [],
            center_title: '',
            center_text: '',
            center_image_existing: '',
            center_image_new: [],
            right_title: '',
            right_text: '',
            right_image_existing: '',
            right_image_new: [],
          })
          break
        case 'lviv-text':
          this.content.push({
            type: 'lviv-text',
            is_anchor: false,
            anchor_title: '',
            target_sign: this.generateSign(32),
            label: '',
            second_label: '',
            title: '',
            description: '',
            float: 'left',
            imageExisting: {
              src: null,
            },
            imageNew: [],
          })
          break
        case 'about-activities':
          this.content.push({
            type: 'about-activities',
            is_anchor: false,
            anchor_title: '',
            target_sign: this.generateSign(32),
            title: '',
            subtitle: '',
          })
          break
        case 'news':
          this.content.push({
            type: 'news',
            is_anchor: false,
            anchor_title: '',
            target_sign: this.generateSign(32),
            title: '',
            subtitle: '',
            read_more_label: '',
          })
          break
      }
    },
    removeInput (index) {
      this.content.splice(index, 1)
    },
    submit () {
      axios.put(`${process.env.VUE_APP_SHKIL_API_URL}/admin/about-page?lang=${this.locale}`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_SHKIL_API_URL}/admin/about-page?lang=${this.locale}`)
        .then(response => {
          this.sectionThemes = response.data.themes
          this.sectionTheme = this.sectionThemes.find(i => i.id === response.data.section_theme)
          this.sectionTitle = response.data.section_title
          this.label = response.data.label
          this.second = response.data.second_label
          this.description = response.data.description
          this.content = response.data.content
          this.content.forEach(i => {
            i.is_anchor = !!i.is_anchor
          })

          this.seoId = response.data.seo.id
          this.metaTitle = response.data.seo.title ? response.data.seo.title : ''
          this.metaDescription = response.data.seo.description ? response.data.seo.description : ''
          this.seoTitle = response.data.seo.seo_title ? response.data.seo.seo_title : ''
          this.seoText = response.data.seo.text ? response.data.seo.text : ''
          this.seoOpenGraphTitle = response.data.seo.og_title ? response.data.seo.og_title : ''
          this.seoOpenGraphDescription = response.data.seo.og_description ? response.data.seo.og_description : ''
          this.seoOpenGraphImageExisting = response.data.seo.image.src ? process.env.VUE_APP_SHKIL_ADMIN + response.data.seo.image.src : null
          this.seoOpenGraphImageId = response.data.seo.imageId
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}

.handle {
  font-size: 24px;
}
</style>
